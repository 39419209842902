const Footer: React.FC = () => {
  return (
    <footer>
      <hr className="mt-5 mb-4" />
      <p className="text-muted">
        <a href="/impressum" className="link-secondary">Impressum</a> | <a href="/datenschutz" className="link-secondary">Datenschutz</a>
      </p>
    </footer>
  );
};

export default Footer;
