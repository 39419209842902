import React, { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import NumberInput from './components/NumberInput';
import IntervalDropdown from './components/IntervalDropdown';
import { KaltmieteInterval } from './KaltmieteInterval';

const BruttoKaltmiete: React.FC = () => {
  const [kaufpreis, setKaufpreis] = useState(100000);
  const [kaltmiete, setKaltmiete] = useState(5000);
  const [kaltmieteInterval, setKaltmieteInterval] = useState(KaltmieteInterval.YEAR);
  const jahreskaltmiete = kaltmieteInterval === KaltmieteInterval.MONTH ? kaltmiete * 12 : kaltmiete;
  const mietrendite = (jahreskaltmiete / kaufpreis * 100).toFixed(2);

  // https://www.pluralsight.com/guides/how-to-use-react-to-set-the-value-of-an-input ??

  const updateKaltmiete = (interval: KaltmieteInterval) => {
    setKaltmieteInterval(interval);
    if (interval === KaltmieteInterval.MONTH) {
      setKaltmiete(Math.floor(kaltmiete / 12));
    } else {
      setKaltmiete(kaltmiete * 12);
    }
  }

  const intervalDropdown = <IntervalDropdown kaltmieteInterval={kaltmieteInterval}
                                             updateKaltmiete={updateKaltmiete}></IntervalDropdown>;

  return (
    <>
      <Form className="mb-5 px-3 py-3" style={{ backgroundColor: 'var(--bs-light)'}}>
        <Row>
          <Col sm={6}>
            <NumberInput id="kaufpreis" label="Kaufpreis" icon="€"
                         value={`${kaufpreis}`}
                         onChange={(e) => setKaufpreis(parseInt(e.target.value))}
                         placeholder="100000"/>
          </Col>
          <Col sm={6}>
            <NumberInput id="jahresmieteinnahmen" label="Kaltmiete" icon="€"
                         onChange={(e) => setKaltmiete(parseInt(e.target.value))}
                         value={`${kaltmiete}`}
                         placeholder="5000"
                         additionalElement={intervalDropdown}/>
          </Col>
        </Row>
        <Row>
          <Col>
            Mietrendite: {mietrendite}%
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default BruttoKaltmiete;
