import React, { useState } from 'react';
import { Col, Container, Row, Form } from 'react-bootstrap';

import Footer from './components/Footer';
import BruttoKaltmiete from './BruttoKaltmiete';
import Header from './components/Header';
import NettoKaltmiete from './NettoKaltmiete';


const App: React.FC = () => {
  const [isNetCalculation, setIsNetCalculation] = useState(false);

  const calculator = isNetCalculation ? <NettoKaltmiete></NettoKaltmiete> :
    <BruttoKaltmiete></BruttoKaltmiete>;

  return (
    <>
      <Header></Header>

      <Container as="main" className="py-4 px-3 mx-auto">
        <Row className="mb-4">
          <Col>
            <h1>Mietrendite berechnen - Brutto & Netto Mietrenditen Rechner</h1>
          </Col>
        </Row>

        <Row className="mb-5">
          <Col>
            <span>Hier kannst du die <strong>Mietrendite berechnen</strong> und prüfen, ob sich die Investition in eine Immobilie lohnt. Gib dafür einfach den Kaufpreis deiner Immobilie und die erwartete Kaltmiete in den Mietrenditen Rechner unten ein und sieh dir das Ergebnis an.</span>
          </Col>
        </Row>

        <Row className="mb-5">
          <Col>
            <Form.Check
              type="switch"
              id="kaltmiete-switch"
              label="Netto Mietrendite berechnen"
              onChange={() => setIsNetCalculation(!isNetCalculation)}
            />
          </Col>
        </Row>

        {calculator}

        <Row className="mb-5">
          <Col>
            <h2>Wie berechnet man die Brutto Mietrendite?</h2>
            <p>Die Formel zur Berechnung der Brutto Mietrendite ist recht einfach:</p>
            <blockquote>
              <code>Brutto Mietrendite = (Jährliche Mieteinnahmen / Kaufpreis) * 100</code>
            </blockquote>
            <p>Die jährlichen Mieteinnahmen setzen sich aus der monatlichen Kaltmiete zusammen. Die Nebenkosten für Warmwasser, etc. kannst du hier ignorieren, denn die meisten Kosten werden auf den Mieter umgelegt.</p>
            <p>Der Kaufpreis wird bei der Brutto Mietrendite ohne Nebenkosten kalkuliert. Deshalb ist die Berechnung Brutto Mietrendite auch nur eine ungefähre Zahl, um einzuschätzen, ob eine weitere Analyse der Immobilie sinnvoll ist.</p>
          </Col>
        </Row>

        <Row className="mb-5">
          <Col>
            <h2>Was ist eine gute Brutto Mietrendite?</h2>
            <p>Die Bewertung der Mietrendite hängt von vielen Faktoren wie Zustand der Immobilie oder der aktuellen Lage am Zinsmarkt ab. Zeigt die Berechnung der Brutto Mietrendite ein Ergebnis von <strong>über 4%</strong> an, kann sich die Investition lohnen. Je höher die Mietrendite ist, desto besser ist es für dich. Nichtsdestotrotz solltest du auch eine zu hohe Mietrendite kritisch hinterfragen - vielleicht gibt es noch irgendwo versteckte Kosten oder der Zustand des Gebäudes ist schlecht.</p>
          </Col>
        </Row>

        <Row>
          <Col>
            <h2>Wie berechnet man die Netto Mietrendite?</h2>
            <p>Die Netto Mietrendite bezieht ein paar weitere Faktoren mit ein. Die Formel lautet</p>
            <blockquote>
              <code>Netto Mietrendite = (Jährliche Mieteinnahmen - Jährliche Ausgaben) / (Kaufpreis + Kaufnebenkosten) * 100</code>
            </blockquote>
            <p>Um die jährlichen Mieteinnahmen zu ermitteln, können Sie entweder die aktuelle Miete nehmen, falls die
              Immobilie bereits vermietet ist, oder eine realistische Schätzung für die erwarteten Mieteinnahmen vornehmen,
              wenn die Immobilie noch nicht vermietet ist.</p>
            <p>Der Kaufpreis und die Kaufnebenkosten bilden die gesamten Anschaffungskosten der Immobilie. Die Kaufnebenkosten beinhalten sämtliche Ausgaben wie Maklergebühren,
              Notarkosten, Kosten für die Eintragung ins Grundbuch, Renovierungskosten und mehr.</p>
          </Col>
        </Row>

        <Footer/>
      </Container>
    </>
  );
};

export default App;
