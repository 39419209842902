import React from 'react';
import { Container, Navbar } from 'react-bootstrap';

const Header: React.FC = () => {
  return (
    <Navbar expand="lg" className="bg-body-tertiary bg-light">
      <Container>
        <Navbar.Brand href="/">mietrenditen-rechner.de</Navbar.Brand>
      </Container>
    </Navbar>
  );
};

export default Header;
