import React from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { KaltmieteInterval } from '../KaltmieteInterval';

export interface IntervalDropdownProps {
  kaltmieteInterval: KaltmieteInterval;
  updateKaltmiete: (interval: KaltmieteInterval) => void;
}
const IntervalDropdown: React.FC<IntervalDropdownProps> = (props) => {

  const buildDropdownItem = (interval: KaltmieteInterval) => <Dropdown.Item
    onClick={() => props.updateKaltmiete(interval)} {...{ active: props.kaltmieteInterval === interval }}>{interval}</Dropdown.Item>

  return (
    <>
      <DropdownButton title={`/ ${props.kaltmieteInterval}`} size="sm" variant="secondary">
        {buildDropdownItem(KaltmieteInterval.YEAR)}
        {buildDropdownItem(KaltmieteInterval.MONTH)}
      </DropdownButton>
    </>
  );
};

export default IntervalDropdown;
