import * as React from 'react';
import { Form, InputGroup } from 'react-bootstrap';

type FormControlElement = HTMLInputElement | HTMLTextAreaElement;
export interface NumberInputProps {
  id: string;
  label: string;
  icon: '€' | '%';
  value: string;
  placeholder?: string;
  onChange?: React.ChangeEventHandler<FormControlElement>;
  decimals?: number;
  readonly?: boolean;
  additionalElement?: React.ReactElement;
}

const NumberInput: React.FC<NumberInputProps> = (props: NumberInputProps) => {
  return (
    <Form.Group className="mb-3" controlId={props.id}>
      <Form.Label>{props.label}</Form.Label>
      <InputGroup className="mb-3">
        <InputGroup.Text>{props.icon}</InputGroup.Text>
        <Form.Control
          type="text"
          value={props.value}
          placeholder={props.placeholder}
          onChange={props.onChange}
          readOnly={props.readonly === true}
        />
        {props.additionalElement}
      </InputGroup>
    </Form.Group>
  );
};

export default NumberInput;
